import {
  SentryError,
  convertError,
  createConnection,
  startListening,
} from '@sentry/shared';
import '@spectrum-web-components/styles/typography.css';
import '@spectrum-web-components/theme/scale-medium.js';
import '@spectrum-web-components/theme/sp-theme.js';
import z from 'zod';

import { validateOrigin } from '$api/configurations-api';
import { Schema as unifiedMobileSchema } from '$flows/unified-mobile/schema';
import { AppEvents, getEvents } from '$scripts/events/events';
import { CriticalOutcome } from '$scripts/outcome';
import { QueryState, queryState } from '$scripts/query-state';
import { broadcastLastResortError, getPostMessagePort } from '$shared/helpers';
import { getLoggers } from '$shared/loggers';
import { unrecoverableErrorFlow } from './flows/error/error-flow';
import { Schema } from './schema';
import { initializeApplication } from './store';

function getStateQS(queryState: QueryState, config: string): any {
  return { authParams: queryState, config: JSON.parse(config ?? '') };
}

/**
 * === HACK ===
 * To support weird SWC behavior.
 * Re-define CustomElements definition to not crash if component already is registered.
 * This should be brought up with the SWC team.
 */
/** @ts-ignore */
window.customElements.defineClone = Object.assign(
  Object.create(Object.getPrototypeOf(customElements)).define,
  customElements
);
window.customElements.define = (name, element) =>
  /** @ts-ignore */
  customElements.get(name) || customElements.defineClone(name, element);

/**
 * Main entry point
 */
(async function main() {
  const { logInfo } = getLoggers();

  const qs = queryState.fromQueryString(document.location.search.substring(1));
  logInfo({
    wrapper: {
      version: qs.sl_version,
      build: qs.sl_build,
      buildTime: qs.sl_buildtime,
    },
  });

  if (qs.wrapper && qs.st_valid_origin) {
    const isValid = await validateOrigin(qs);
    if (!isValid) {
      throw new Error('Origin validation failed.');
    }
  }

  const { port, state } = qs.wrapper
    ? await createConnection(qs.asserted_origin)
    : {
        port: getPostMessagePort(qs.platform),
        state: getStateQS(qs, qs.config),
      };

  const events = getEvents(port);

  try {
    const authParams = queryState.fromObject(state.authParams);
    const config = qs.wrapper
      ? Schema.safeParse(state.config)
      : unifiedMobileSchema.safeParse(state.config);

    if (!config.success) {
      throw SentryError.unrecoverable(
        'Failed to parse config.',
        new z.ZodError(config.error.issues)
      );
    }

    initializeApplication({
      queryState: authParams,
      config: config.data,
      events: events as AppEvents,
      eventListener:
        port instanceof MessagePort
          ? startListening(port as MessagePort)
          : ((() => {}) as any),
      port,
      locale: qs.locale,
    });
  } catch (e) {
    return unrecoverableErrorFlow(
      events,
      new CriticalOutcome(
        SentryError.unrecoverable(
          'Application initialization error.',
          convertError(e)
        )
      )
    );
  }
})().catch((e: Error) => {
  try {
    unrecoverableErrorFlow(
      null as any,
      new CriticalOutcome(
        SentryError.unrecoverable(
          'Application initialization error.',
          convertError(e)
        )
      )
    );
  } catch (e) {
    broadcastLastResortError(e);
  }
});

export {};
