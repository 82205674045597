import { CompleteAccountFlowConfig } from '$flows/complete-account/schema';
import { Schema as LargeButtonsPageSchema } from '$pages/variants/large-buttons/schema';
import { z } from 'zod';

const FeatureFlags = z
  .object({
    hasPasskeys: z.boolean().default(false),
  })
  .default({ hasPasskeys: false });

// Features
const FLOW_SCHEMA = z.object({
  features: FeatureFlags,
});

export const LargeButtonsFlowConfig = FLOW_SCHEMA
  // Pages
  .merge(LargeButtonsPageSchema)
  // Sub Flows
  .merge(CompleteAccountFlowConfig);

export const Schema = z
  .object({
    variant: z.literal('large-buttons'),
  })
  .merge(LargeButtonsFlowConfig);

export type FlowConfig = z.infer<typeof LargeButtonsFlowConfig>;
