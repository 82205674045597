import { CompleteAccountFields } from '$flows/complete-account/complete-account';
import { Outcome } from '$scripts/outcome';
import { AuthFailReason } from '$shared/types/auth-failed-reasons';
import { SocialProvider } from '$shared/types/social-provider';

export class RedirectOutcome extends Outcome<{
  url: string;
}> {}
export class TokenOutcome extends Outcome<{ token: string }> {}
export class AuthCodeOutcome extends Outcome<{ code: string }> {}
export class AuthFailedOutcome extends Outcome<{
  reason: AuthFailReason;
  fallbackUrl?: string;
}> {}

export class CompleteAccountRequiredOutcome extends Outcome<{
  fields: Partial<CompleteAccountFields>;
  token: string;
  socialProvider: SocialProvider;
  client_id: string;
}> {}
