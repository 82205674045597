import { z } from 'zod';

import { Schema as CompleteAccountPageSchema } from '$pages/variants/complete-account/schema';

const FLOW_SCHEMA = z.object({});

export const CompleteAccountFlowConfig = FLOW_SCHEMA
  // Pages
  .merge(CompleteAccountPageSchema);
// Flows

export const Schema = z
  .object({
    variant: z.literal('complete-account'),
  })
  .merge(CompleteAccountFlowConfig);

export type FlowConfig = z.infer<typeof CompleteAccountFlowConfig>;
