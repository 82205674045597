import { CompleteAccountFlowConfig } from '$flows/complete-account/schema';
import { Schema as ExpressThemePageSchema } from '$pages/variants/express-theme/schema';
import { z } from 'zod';

const FLOW_SCHEMA = z.object({});

export const LargeButtonsFlowConfig = FLOW_SCHEMA
  // Pages
  .merge(ExpressThemePageSchema)
  // Sub Flows
  .merge(CompleteAccountFlowConfig);

export const Schema = z
  .object({
    variant: z.literal('express-theme'),
  })
  .merge(LargeButtonsFlowConfig);

export type FlowConfig = z.infer<typeof Schema>;
