import { StoreApi } from 'zustand';

export type LoadingState<T> =
  | ({ loading: true } & Partial<T>)
  | ({ loading: false } & T);

export const unpack = <T>(state: LoadingState<T>): readonly [boolean, T] => {
  const { loading, ...restState } = state;
  return [loading, restState as T];
};

export function asyncStoreHelper<T>(
  slice: StoreApi<LoadingState<T>>
): Promise<T> {
  return new Promise(resolve => {
    function doneLoading(state: LoadingState<T>) {
      const [loading, restState] = unpack(state);
      if (!loading) {
        resolve(restState);
      }

      return !loading;
    }

    if (!doneLoading(slice.getState())) {
      slice.subscribe(doneLoading);
    }
  });
}

export type Setter<TState> = (partial: Partial<TState>) => void;
export type Getter<TState> = () => TState;
export type MethodFn<TState, TMethod extends (...args: any[]) => TState> = (
  state: TState,
  ...args: Parameters<TMethod>
) => Partial<TState>;

export function methodCreator<TState>(
  set: Setter<TState>,
  get: Getter<TState> | Getter<LoadingState<TState>>
) {
  return function <TMethod extends (...args: any[]) => TState>(
    fn: MethodFn<TState, TMethod>
  ) {
    return function (...args: Parameters<TMethod>) {
      const state = get() as TState;
      const value = fn(state, ...args);
      set(value);
      return value;
    };
  };
}

export const push = <T>(arr: T[], item: T): T[] => [...arr, item];
export const unshift = <T>(arr: T[], item: T): T[] => [item, ...arr];
export const pop = <T>(arr: T[]): T[] => arr.slice(0, -1);
export const shift = <T>(arr: T[]): T[] => arr.slice(1);

export function getBirthDateFromMonthAndYear(month: number, year: number) {
  return new Date(year, month, calculateDefaultDay({ month, year }));
}

export function calculateAge(birthDate: Date) {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}

export const calculateDefaultDay = ({
  year,
  month,
}: {
  month: number;
  year: number;
}) => {
  const februaryIndex = 1;
  const lastFebruaryDay = 28;
  const leapFebruaryDay = 29;

  const currentDay = new Date().getUTCDate();
  const day = isMaxDay({ year, month, day: currentDay }) ? 30 : currentDay;

  if (year && month && month === februaryIndex) {
    const maxDay = isLeapYear(year) ? leapFebruaryDay : lastFebruaryDay;
    return currentDay >= maxDay ? maxDay : day;
  }

  return day;
};

const isLeapYear = (year: number) =>
  year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);

const isMaxDay = ({
  year,
  month,
  day,
}: {
  year: number;
  month: number;
  day: number;
}) => new Date(year, month, day).getDate() !== day;
