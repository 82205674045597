export class AsyncWrapper<T> {
  constructor(
    private promise: Promise<T>,
    private result: T | null = null,
    public loading: boolean = true
  ) {
    this.promise = promise;

    this.promise
      .then(res => {
        this.result = res;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onFulfilled(onfulfilled: (value: T) => any) {
    this.promise.then.call(this.promise, onfulfilled);
    return this;
  }

  onError(onError: (value: T) => any) {
    this.promise.catch.call(this.promise, onError);
    return this;
  }

  getOrDefault(defaultValue: T): T {
    if (this.loading || this.result === null) {
      return defaultValue;
    }

    return this.result;
  }

  get(): T | null;
  get(shouldEnforceType: true): T;
  get(shouldEnforceType = false): T | null {
    return shouldEnforceType ? (this.result as T) : this.result;
  }

  static from<T>(asyncable: Promise<T> | T): AsyncWrapper<T> {
    if (asyncable instanceof AsyncWrapper) {
      return new AsyncWrapper(
        asyncable.promise,
        asyncable.result,
        !asyncable.result
      );
    }

    if (asyncable instanceof Promise) {
      return new AsyncWrapper(asyncable);
    }

    return new AsyncWrapper(Promise.resolve(asyncable));
  }
}

export type AsyncMap<TMap extends Record<string, any>> = {
  [K in keyof TMap]: AsyncWrapper<TMap[K]>;
};
