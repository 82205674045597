import { fetchSocialProviders } from '$api/configurations-api';
import { SocialProvider } from '$shared/types/social-provider';
import { MobileEvents } from '$scripts/events/events';

import create, { StateCreator } from 'zustand/vanilla';

import { Locale } from '../locales';

export interface ConfigurationsState {
  socialProviders: SocialProvider[];
  fetchAndCacheSocialProviders: () => Promise<SocialProvider[]>;
  fetchAndCacheSocialProvidersNoBackup: () => Promise<SocialProvider[]>;
  hasSentLoadEvent: boolean;
  isLocaleLoading: boolean;
  setIsLocaleLoading: (loading: boolean) => void;
  setLocale: (locale: Locale) => void;
  locale: Locale | null;
}

export const createConfigurationsStore: StateCreator<ConfigurationsState> = (
  set,
  get
) => ({
  socialProviders: [],
  isLocaleLoading: false,
  hasSentLoadEvent: false,
  locale: null,
  setIsLocaleLoading: (loading: boolean) => set({ isLocaleLoading: loading }),
  setLocale: (locale: Locale) => set({ isLocaleLoading: false, locale }),
  fetchAndCacheSocialProviders: async () => {
    let socialProviders = get().socialProviders;
    if (!socialProviders.length) {
      // TODO: Call failure error handling
      socialProviders = await fetchSocialProviders();
      set({ socialProviders });
    }
    return socialProviders;
  },
  fetchAndCacheSocialProvidersNoBackup: async () => {
    let socialProviders = get().socialProviders;
    if (!socialProviders.length) {
      // TODO: Call failure error handling
      socialProviders = await fetchSocialProviders(false);
      set({ socialProviders });
    }
    return socialProviders;
  },
});

const store = create<ConfigurationsState>(createConfigurationsStore);

export const getConfigurationsStore = () => store;

export const getConfigurationsState = () => getConfigurationsStore().getState();
