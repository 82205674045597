import { nanoid } from 'nanoid';
import { urls, envs } from '@sentry/shared';
import { PROVIDERS } from '../../shared/types/social-provider';

const { getPopupUrl } = urls.create(envs.getAppEnv);

export const googleQueryString = {
  client_id:
    '60187432700-kuegfobnu2gras7jgo316k1h530t8v9t.apps.googleusercontent.com',
  redirect_uri: getPopupUrl() || '',
  scope: 'email openid profile',
  response_type: 'token id_token',
  nonce: nanoid(),
  state: JSON.stringify({ provider: PROVIDERS.google }),
};
