import { Schema as UnifiedMobilePageSchema } from '$pages/variants/unified-mobile/schema';
import { z } from 'zod';

const FLOW_SCHEMA = z.object({});

export const UnifiedMobileFlowConfig = FLOW_SCHEMA
  // Pages
  .merge(UnifiedMobilePageSchema);
// Sub Flows

export const Schema = z
  .object({
    variant: z.literal('unified-mobile'),
  })
  .merge(UnifiedMobileFlowConfig);

export type FlowConfig = z.infer<typeof Schema>;
