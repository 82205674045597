import { SentryError } from '@sentry/shared';

export class Outcome<T = unknown> {
  constructor(public value: T, public isHandled = false) {
    this.value = value;
  }

  getValue(): T {
    return this.value;
  }

  setHandled() {
    this.isHandled = true;
  }

  of<TAssert extends Outcome>(
    this: Outcome,
    base: { new (...args: any[]): TAssert }
  ): this is TAssert {
    return this instanceof base;
  }
}

type IsCriticalOutcome<T> = T extends CriticalOutcome ? true : false;
export class CriticalOutcome extends Outcome<SentryError> {}

export type Errorable<T> = IsCriticalOutcome<T> extends true
  ? T
  : T | CriticalOutcome;

export type OutcomeReturn<T> = Promise<Errorable<T>>;
