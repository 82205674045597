import { z } from 'zod';
import { Schema as completeAccountSchema } from './complete-account/schema';
import { Schema as unifiedMobileSchema } from './unified-mobile/schema';
import { Schema as largeButtonsSchema } from './variants/large-buttons/schema';
import { Schema as expressThemeSchema } from './variants/express-theme/schema';

export const flowSchemas = z.discriminatedUnion('variant', [
  largeButtonsSchema,
  expressThemeSchema,
  unifiedMobileSchema,
  completeAccountSchema,
]);

export { largeButtonsSchema, unifiedMobileSchema, expressThemeSchema };
