import { getLoggers } from '$shared/loggers';
import { LoggerFn } from '@sentry/shared';
import { CUSTOM_PAGENAME_PROP } from './constants';

export class AnalyticsEvent {
  protected logger: LoggerFn;

  constructor() {
    const { logAnalytics } = getLoggers();
    this.logger = logAnalytics;
  }

  setup() {}
  track() {}
  cleanup() {}

  cycle() {
    this.setup();
    this.track();
    this.cleanup();
  }

  webInteraction(webInteraction: { name: string }) {
    return {
      xdm: {},
      data: {
        web: {
          webInteraction,
        },
      },
    };
  }

  send(type: 'event' | 'pageload', event: any) {
    console.log('Sent event', { [type]: event });
    this.logger({
      [type]: event,
    });
    window._satellite.track(type, event);
  }
}

class PageLoadEvent extends AnalyticsEvent {
  private pageName: string;

  constructor(pageName: string) {
    super();
    this.pageName = pageName;
  }

  track() {
    this.send('pageload', {
      [CUSTOM_PAGENAME_PROP]: this.pageName,
    });
  }

  setup() {
    window.alloy_all.set(CUSTOM_PAGENAME_PROP, this.pageName);
  }
}

class InteractionEvent extends AnalyticsEvent {
  private eventName: string;

  constructor(eventName: string) {
    super();
    this.eventName = eventName;
  }

  track() {
    this.send(
      'event',
      this.webInteraction({
        name: `Account:IMS-Light:${this.eventName}`,
      })
    );
  }
}

export const createPageLoadEvent = ({
  pageName,
}: {
  pageName: string;
}): AnalyticsEvent => new PageLoadEvent(pageName);

export const createInteractionEvent = ({
  eventName,
}: {
  eventName: string;
}): AnalyticsEvent => new InteractionEvent(eventName);
