import {
  AUTH_FAILED_REASON,
  AuthFailReason,
} from '$shared/types/auth-failed-reasons';
import {
  Event,
  OpenModalEvent,
  CloseModalEvent,
  OpenPopupEvent,
  SentryError,
  eventFactory,
} from '@sentry/shared';

export type PostMessagePort = { postMessage: (arg: any) => void };

export const AppRedirectEvent = Event.create({
  name: 'redirect',
  side: 'proxy',
});

export const AppDeeplinkEvent = Event.create({
  name: 'deeplink',
  side: 'proxy',
});
export const OnErrorEvent = Event.create<SentryError>({
  name: 'on-error',
  side: 'proxy',
  data: SentryError.critical('Unknown error.'),
});

export type AuthFailedEventData = {
  reason: AuthFailReason;
  fallbackUrl?: string;
};

export const OnAuthFailedEvent = Event.create<AuthFailedEventData>({
  name: 'on-auth-failed',
  side: 'proxy',
  data: {
    reason: AUTH_FAILED_REASON.unknown,
  },
});

export const HelloEvent = Event.create({
  name: 'hello',
  side: 'proxy',
  data: {
    name: 'hello',
  },
});

export const OnLoadEvent = Event.create({
  name: 'on-load',
  side: 'proxy',
});

export const OnTokenEvent = Event.create({ name: 'on-token', side: 'proxy' });
export const OnAuthCodeEvent = Event.create({
  name: 'on-auth-code',
  side: 'proxy',
});

export const OnProviderClicked = Event.create({
  name: 'on-provider-clicked',
  side: 'proxy',
});

export function getAppEvents(port: MessagePort) {
  return {
    redirect: eventFactory<string>(port)(AppRedirectEvent),
    openPopup: eventFactory<string>(port)(OpenPopupEvent),
    openModal: eventFactory<{
      dimensions: { height: number; width: number };
      reason: string;
    }>(port)(OpenModalEvent),
    closeModal: eventFactory(port)(CloseModalEvent),
    sendProvider: eventFactory<string>(port)(OnProviderClicked),
    sendToken: eventFactory<string>(port)(OnTokenEvent),
    sendAuthCode: eventFactory<string>(port)(OnAuthCodeEvent),
    sendAuthFailed: eventFactory<AuthFailedEventData>(port)(OnAuthFailedEvent),
    sendError: eventFactory<SentryError>(port)(OnErrorEvent),
    sendLoad: eventFactory<Object>(port)(OnLoadEvent),
  };
}

export function getMobileEvents(messenger: PostMessagePort) {
  const port = {
    postMessage: (event: Event) => messenger.postMessage(event),
  } as MessagePort;
  return {
    redirect: eventFactory<Object>(port)(AppRedirectEvent),
    deeplink: eventFactory<Object>(port)(AppDeeplinkEvent),
    sendError: eventFactory<SentryError>(port)(OnErrorEvent),
    sendHello: eventFactory<Object>(port)(HelloEvent),
  };
}

export type AppEvents = ReturnType<typeof getAppEvents>;
export type MobileEvents = ReturnType<typeof getMobileEvents>;

export function getEvents(messenger: MessagePort): AppEvents;
export function getEvents(messenger: PostMessagePort): MobileEvents;
export function getEvents(
  messenger: MessagePort | PostMessagePort
): AppEvents | MobileEvents {
  if (messenger instanceof MessagePort) {
    return getAppEvents(messenger);
  } else {
    return getMobileEvents(messenger);
  }
}

export async function eventsReducer(event: Event, actions: AppEvents) {
  switch (true) {
    default:
      return console.log(event);
  }
}
