import { URLSearchParamsBuilder } from '$scripts/urls/builder';
import {
  Event,
  EventScheduler,
  OpenModalCompleteEvent,
  OpenPopupBlockedEvent,
  OpenPopupCompleteEvent,
  OpenPopupErrorEvent,
} from '@sentry/shared';
import { AppEvents } from './events';

/**
 * Dispatchers take an event, an input and listen for a return event.
 */

export class PopupBlockedError extends Error {}

export async function openPopupDispatcher<TEvent extends Event>(
  expectedEvent: TEvent,
  url: URLSearchParamsBuilder,
  events: AppEvents,
  eventScheduler: EventScheduler
): Promise<TEvent> {
  const popupOpened = eventScheduler.listenFor(OpenPopupCompleteEvent);
  const popupBlocked = eventScheduler.listenFor(OpenPopupBlockedEvent);

  const resultPromise = Promise.race([popupOpened, popupBlocked]);
  events.openPopup(url({ state: expectedEvent.toString() })());

  const popupResult = await resultPromise;
  if (OpenPopupBlockedEvent.compareTo(popupResult)) {
    return Promise.reject(new PopupBlockedError());
  } else {
    const expectedEventPromise = eventScheduler.listenFor(expectedEvent);
    const errorEventPromise = eventScheduler.listenFor(OpenPopupErrorEvent);

    const result = Promise.race([
      expectedEventPromise,
      errorEventPromise.then(error => Promise.reject(error)),
    ]);

    return result as Promise<TEvent>;
  }
}

export async function openModalDispatcher(
  port: MessagePort,
  events: AppEvents,
  eventScheduler: EventScheduler,
  dimensions: { width: number; height: number },
  reason: string
): Promise<typeof OpenModalCompleteEvent> {
  const returnEventPromise = eventScheduler.listenFor(OpenModalCompleteEvent);

  events.openModal({
    dimensions,
    reason,
  });
  return returnEventPromise;
}
