import { MIN_CARD_DIMENSIONS } from '$shared/constants/dimensions';
import { createPage } from '$store/index';

export * as CriticalErrorPage from './controller';

import { PageOutcome as CriticalErrorPageOutcome } from './controller';
import { PageState as CriticalErrorPageState } from './state';

const dimensions = MIN_CARD_DIMENSIONS;

export const criticalErrorPage = {
  load: () => import('./page-fetch'),
  create(initialState: CriticalErrorPageState) {
    return createPage<CriticalErrorPageOutcome, CriticalErrorPageState>(
      this.load,
      { initialState, dimensions, forceModal: false }
    );
  },
};

export const unrecoverableErrorPage = {
  load: () => import('./page-fetch'),
  create(initialState: CriticalErrorPageState) {
    return createPage<null, CriticalErrorPageState>(this.load, {
      initialState,
      dimensions,
      forceModal: false,
    });
  },
};
