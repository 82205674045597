import { criticalErrorPage, unrecoverableErrorPage } from '$pages/error-page';
import { AppEvents } from '$scripts/events/events';
import { CriticalOutcome } from '$scripts/outcome';
import {
  broadcastLastResortError,
  getRelay,
  logCritical,
  logError,
} from '$shared/helpers';
import { getApplicationState } from '$store/application-store';
import { AuthCodeOutcome, TokenOutcome } from '$traits/outcomes';
import { SentryError } from '@sentry/shared';
import { unhandledEndOfFlow } from '../helpers/end-of-flow';

export async function criticalErrorFlow(
  events: AppEvents,
  passedOutcome: CriticalOutcome
): Promise<any> {
  const {
    config: { variant },
    queryState,
  } = await getApplicationState();

  // UME Exception - We don't have critical errors in UME, only unrecoverable.
  if (variant === 'unified-mobile') {
    const error = passedOutcome.getValue();
    return unrecoverableErrorFlow(
      events,
      new CriticalOutcome(SentryError.unrecoverable(error.message))
    );
  }

  events.sendError(passedOutcome.getValue());
  logCritical(passedOutcome.getValue());

  const outcome = await criticalErrorPage.create({
    shouldAllowSignIn: true,
    debugId: queryState.relay,
  });

  if (outcome.of(TokenOutcome)) {
    const { token } = outcome.getValue();
    events.sendToken(token);
    return outcome;
  }

  if (outcome.of(AuthCodeOutcome)) {
    const { code } = outcome.getValue();
    events.sendAuthCode(code);
    return outcome;
  }

  if (outcome.of(CriticalOutcome)) {
    return unrecoverableErrorFlow(events, outcome);
  }

  return unhandledEndOfFlow(outcome);
}

export async function unrecoverableErrorFlow(
  events: Pick<AppEvents, 'sendError'>,
  passedOutcome: CriticalOutcome
): Promise<any> {
  try {
    if (events && events.sendError) {
      logError(passedOutcome.getValue());
      events.sendError(passedOutcome.getValue());
    } else {
      broadcastLastResortError(passedOutcome.getValue());
    }

    await unrecoverableErrorPage.create({
      shouldAllowSignIn: false,
      debugId: getRelay(),
    });
  } catch (e) {
    broadcastLastResortError(e);
  }
  return;
}
