import { OutcomeListener, PageController } from '$scripts/base-controller';
import { CriticalOutcome } from '$scripts/outcome';
import { signInAdobePopup } from '$scripts/urls/handlers';
import { getApplicationState } from '$store/application-store';
import { AuthCodeOutcome, TokenOutcome } from '$traits/outcomes';
import { SentryError } from '@sentry/shared';

export type PageOutcome = TokenOutcome | AuthCodeOutcome;

export class Controller extends PageController<PageOutcome, any> {
  getApplicationState = getApplicationState;

  @OutcomeListener()
  async onSignInClicked() {
    try {
      const { events, eventListener, queryState } =
        await this.getApplicationState();

      if (queryState.response_type === 'code') {
        const {
          data: { code },
        } = await signInAdobePopup.withAuthCode(
          events,
          eventListener(),
          queryState
        );

        return new AuthCodeOutcome({ code });
      } else {
        const {
          data: { access_token },
        } = await signInAdobePopup.withAccessToken(
          events,
          eventListener(),
          queryState
        );

        return new TokenOutcome({ token: access_token });
      }
    } catch (e) {
      const message = e instanceof Error ? e.message : (e as string);
      return new CriticalOutcome(SentryError.unrecoverable(message));
    }
  }
}
