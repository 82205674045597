import { envs, urls } from '@sentry/shared';
import { googleQueryString } from './provider-query-strings';

import {
  GoogleMockProvider,
  SocialProvider,
} from '$shared/types/social-provider';
import { urlBuilder } from './builder';
import { getLocale } from '$shared/helpers';

const { getPopupUrl, getApiUrl, getFederatedUrl, getAuthServicesUrl } =
  urls.create(envs.getAppEnv);

function transformLocale(locale: string) {
  // transforms en-us to en_US
  return locale.replace(
    /-(\w+)/,
    (_, countryCode) => '_' + `${countryCode.toUpperCase()}`
  );
}

const ADOBE_AUTHORIZE = urlBuilder('/ims/authorize/v3', getApiUrl());
const FEDERATED_AUTHORIZE = (
  socialProvider: SocialProvider | GoogleMockProvider
) => urlBuilder(`/federated/authorize/${socialProvider}`, getFederatedUrl());

const TEMPEST_DEEPLINK = urlBuilder(
  `/${transformLocale(getLocale())}/deeplink.html`,
  getAuthServicesUrl()
);

export const tempestRedirectUrl = TEMPEST_DEEPLINK({});

const GOOGLE_AUTHORIZE = urlBuilder(
  '/o/oauth2/v2/auth/oauthchooseaccount',
  'https://accounts.google.com'
);

const googleSignInUrl = GOOGLE_AUTHORIZE(googleQueryString);

export const signInAdobeUrl = ADOBE_AUTHORIZE({});
export const signInAdobePopupUrl = ADOBE_AUTHORIZE({
  redirect_uri: getPopupUrl(),
  response_type: 'token',
});

export const createAccountAdobeUrl = ADOBE_AUTHORIZE({
  idp_flow: 'create_account',
});
export const createAccountAdobePopupUrl = createAccountAdobeUrl({
  redirect_uri: getPopupUrl(),
  response_type: 'token',
});

export const socialWebDeeplinkUrl = ADOBE_AUTHORIZE({
  idp_flow: 'social.deep_link.web',
});
export const socialWebDeeplinkPopupUrl = socialWebDeeplinkUrl({
  redirect_uri: getPopupUrl(),
  response_type: 'token',
});

export const federatedPopupUrl = (
  socialProvider: SocialProvider | GoogleMockProvider
) =>
  FEDERATED_AUTHORIZE(socialProvider)({
    redirectUri: getPopupUrl(),
    response_mode: 'fragment',
  });

export const passkeyDeeplinkUrl = ADOBE_AUTHORIZE({
  idp_flow: 'passkeys.deep_link.web',
});
export const passkeyDeeplinkPopupUrl = passkeyDeeplinkUrl({
  redirect_uri: getPopupUrl(),
  response_type: 'token',
});
