import { LoadingState } from '$store/helpers';
import { StoreApi } from 'zustand';

export type PageEvent = { name: string; data: any };

export function OutcomeListener() {
  return <T>(
    target: HasOutcome<T>,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    const originalMethod = descriptor.value;
    descriptor.value = async function (this: HasOutcome<T>, ...args: any[]) {
      const outcome = await originalMethod.apply(this, args);
      this.outcomePromise.resolve(outcome);
    };

    return descriptor;
  };
}

export class OutcomePromise<T> {
  public promise: Promise<T>;
  public resolve: (outcome: T) => void = () => {};

  constructor() {
    this.promise = new Promise(resolve => (this.resolve = resolve));
  }
}

export interface HasOutcome<T> {
  outcomePromise: OutcomePromise<T>;
}

export class PageController<T, TState> implements HasOutcome<T> {
  public outcomePromise: OutcomePromise<T> = new OutcomePromise<T>();

  constructor(private store: StoreApi<TState | LoadingState<TState>>) {
    this.store = store;
  }

  get state() {
    return this.store.getState() as TState;
  }
}
