export * from './analytics-store';
export * from './application-store';
export * from './configurations-store';

import { pageCreator } from '$scripts/page-creator';
import { setSentry } from '$shared/helpers';
import { Locale } from 'locales';
import { getAnalyticsState } from './analytics-store';
import {
  ApplicationState,
  getApplicationState,
  getApplicationStore,
} from './application-store';
import {
  getConfigurationsState,
  getConfigurationsStore,
} from './configurations-store';

export const initializeApplication = (
  slice: ApplicationState & { locale: Locale }
) => {
  if (slice.config.variant === 'unified-mobile') {
    document.getElementById('app')?.setAttribute('class', 'unified-mobile');
  }

  setSentry(slice);
  getApplicationStore().setState({ loading: false, ...slice });
  getConfigurationsStore().setState({
    locale: slice.locale,
  });
};

export const createPage = pageCreator(document.getElementById('app'), {
  getAnalytics: getAnalyticsState,
  getConfigurationsState,
  getApplicationState,
});
