import { flowSchemas } from '$flows/schema';
import z from 'zod';

export const BaseSchema = z.object({
  version: z.string().default('1.0.0'),
  build: z.string().default(''),
  buildTime: z.number().default(0),
  popup: z.boolean().default(false),
  modal: z.boolean().default(false),
});

export const Schema = flowSchemas.and(BaseSchema);
