import create, { StateCreator } from 'zustand/vanilla';
import { AnalyticsEvent } from '../scripts/analytics/events';
import { pop, push, shift, unshift } from './helpers';

type Methods = {
  takeEvents: () => AnalyticsEvent[];
  setIsAnalyticsLoading: (loading: boolean) => void;
  unshift: (item: AnalyticsEvent) => void;
  push: (item: AnalyticsEvent) => void;
  pop: () => void;
  shift: () => void;
}

type Properties = {
  events: AnalyticsEvent[];
  isAnalyticsLoading: boolean;
}

export type AnalyticsState = Methods & Properties;

type StateHelper<T> = (...args: Parameters<typeof createAnalyticsStore>) => T;
const takeEvents: StateHelper<AnalyticsEvent[]> = (set, get) => {
  const { events } = get();

  if (events.length > 0) {
    set({ events: [] })
  }

  return events;
}

export const createAnalyticsStore: StateCreator<AnalyticsState> = (
  set,
  get,
  ...rest
) => ({
  takeEvents: () => takeEvents(set, get, ...rest),
  unshift: (event: AnalyticsEvent) => set({ events: unshift(get().events, event) }),
  push: (event: AnalyticsEvent) => set({ events: push(get().events, event) }),
  pop: () => set({ events: pop(get().events) }),
  shift: () => set({ events: shift(get().events) }),
  setIsAnalyticsLoading: (loading: boolean) => set({ isAnalyticsLoading: loading }),
  events: [],
  isAnalyticsLoading: true,
});

const store = create(createAnalyticsStore);

export const getAnalyticsStore = () => store;

export const getAnalyticsState = () => getAnalyticsStore().getState();
