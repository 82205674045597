const PARAMETER_BLACKLIST = [
  'sl_build',
  'sl_buildtime',
];

export type URLSearchParamsBuilder = {
  (searchParams: Record<string, string>): URLSearchParamsBuilder;
  (): string;
};

export type URLBuilder = (
  endpoint: string,
  baseUrl: string
) => URLSearchParamsBuilder;

const validateString = (input: string, inputName: string): void => {
  if (typeof input !== 'string') {
    throw new TypeError(`${inputName} must be a string`);
  }

  if (input.length === 0) {
    throw new Error(`${inputName} cannot be an empty string`);
  }
};

export const urlBuilder: URLBuilder = (endpoint: string, baseUrl: string) => {
  validateString(endpoint, 'Endpoint');
  validateString(baseUrl, 'Base URL');

  try {
    new URL(endpoint, baseUrl);
  } catch (error) {
    throw new Error('Invalid endpoint and/or base URL');
  }

  function urlBuild(url: URL): URLSearchParamsBuilder {
    function addSearchParams(): string;
    function addSearchParams(
      searchParams: Record<string, string>
    ): URLSearchParamsBuilder;
    function addSearchParams(
      searchParams?: Record<string, string>
    ): URLSearchParamsBuilder | string {
      const urlProgress = new URL(url.toString());
      if (searchParams === undefined) return urlProgress.toString();

      Object.entries(searchParams)
      .filter(([key]) => !PARAMETER_BLACKLIST.includes(key))
      .forEach(([key, value]) => {
        validateString(key, 'Search parameter key');
        validateString(value, 'Search parameter value');
        urlProgress.searchParams.set(key, value);
      });

      return urlBuild(urlProgress);
    }

    return addSearchParams;
  }

  return urlBuild(new URL(endpoint, baseUrl));
};
