import z from 'zod';
import { ObjectValues } from '$shared/helpers';

const CONSENT_VALUES = {
  implicit: 'implicit',
  explicit: 'explicit',
  soft: 'soft',
} as const;

type Consent = ObjectValues<typeof CONSENT_VALUES>;

const CONSENT_PROFILES = {
  adobeIdSignUp: 'adobe-id-sign-up',
  purchase: 'purchase',
  quoteRequest: 'quote-request',
  trial: 'trial',
  free: 'free',
} as const;

const ConsentProfileSchema = z.nativeEnum(CONSENT_PROFILES);

type ConsentProfile = z.infer<typeof ConsentProfileSchema>;

export {
  CONSENT_VALUES,
  CONSENT_PROFILES,
  ConsentProfileSchema,
  ConsentProfile,
  Consent,
};
