import { AppEvents, PostMessagePort } from '$scripts/events/events';
import { EventScheduler } from '@sentry/shared';
import { QueryState } from 'scripts/query-state';
import { z } from 'zod';
import create, { StateCreator } from 'zustand/vanilla';
import { Schema } from '../schema';
import { LoadingState, asyncStoreHelper } from './helpers';

export type ApplicationState = {
  events: AppEvents;
  eventListener: () => EventScheduler;
  config: z.infer<typeof Schema>;
  port: MessagePort | PostMessagePort;
  queryState: QueryState;
};

export const createApplicationStore: StateCreator<
  LoadingState<ApplicationState>
> = () => ({
  loading: true,
  events: undefined,
  eventListener: undefined,
  config: undefined,
  port: undefined,
  queryState: undefined,
});

const store = create<LoadingState<ApplicationState>>(createApplicationStore);

export const getApplicationStore = () => store;

export const getApplicationState = async (): Promise<ApplicationState> => {
  const state = getApplicationStore();
  const slice = await asyncStoreHelper<ApplicationState>(state);
  return slice;
};
