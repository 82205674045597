import compare from 'semver-compare';
import { queryState } from './query-state';

export function getVersion() {
  const qs = queryState.fromQueryString(document.location.search.substring(1));

  return qs.sl_version;
}

export function createVersionChecker(getVersion: () => string) {
  return (version: string) => () => compare(getVersion(), version) >= 0;
}

export type Requirement = () => boolean;
export type FeatureCheck = () => boolean;

export function createFeatureChecker(
  requirements: Requirement[]
): FeatureCheck {
  return () => requirements.every(check => check());
}

export const versionCheck = {
  'greaterThan1.0.1': createVersionChecker(getVersion)('1.0.1'),
  disabled: () => false,
};

export const features = {
  hasTestFeature: createFeatureChecker([versionCheck['greaterThan1.0.1']]),
  hasModalMode: createFeatureChecker([versionCheck['greaterThan1.0.1']]),
};
