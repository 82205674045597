import z from 'zod';

import {
  BACKUP_PROVIDERS,
  SOCIAL_PROVIDERS,
  SocialProvider,
} from '$shared/types/social-provider';
import { getApplicationState } from '$store/application-store';

import { QueryState } from '$scripts/query-state';
import { logInfo, responseToInfo } from '$shared/helpers';
import { UnionToTuple } from '$shared/types/helpers';
import { PATHNAMES } from './constants';
import { getImsEndpoint } from './index';

export type SocialProvidersEnum = UnionToTuple<keyof typeof SOCIAL_PROVIDERS>;

const SocialProviderSchema = z
  .object({
    providerName: z.enum(
      Object.values(SOCIAL_PROVIDERS) as SocialProvidersEnum
    ),
  })
  .transform(socialProvider => socialProvider.providerName);

const FetchSocialProvidersResponse = z.array(SocialProviderSchema);

const filterParse = (providers: unknown[]) =>
  providers.filter(item => SocialProviderSchema.safeParse(item).success);

export async function fetchSocialProviders(
  hasFallbackProviders = true
): Promise<SocialProvider[]> {
  const imsEndpoint = await getImsEndpoint();
  const {
    queryState: { client_id },
  } = await getApplicationState();

  const promise = imsEndpoint
    .url(PATHNAMES.socialProviders)
    .query({ client_id })
    .get()
    .json(r => FetchSocialProvidersResponse.parse(filterParse(r)));

  return hasFallbackProviders
    ? promise.catch(e => {
        logInfo(
          'Failed to fetch social providers, using backup providers.',
          e.response && responseToInfo(e.response)
        );
        return BACKUP_PROVIDERS;
      })
    : promise;
}

export async function validateOrigin(
  qs: QueryState & { asserted_origin: string }
): Promise<boolean> {
  const imsEndpoint = await getImsEndpoint({
    client_id: qs.client_id,
    relay: qs.relay,
  });

  const promise = imsEndpoint
    .url(PATHNAMES.validateOrigin)
    .query({ client_id: qs.client_id, redirect_uri: qs.asserted_origin })
    .get()
    .json(res =>
      z
        .object({ valid: z.boolean() })
        .transform(r => r.valid)
        .parse(res)
    );

  return promise.catch(e => {
    logInfo(
      'Validate origin call failed.',
      e.response && responseToInfo(e.response)
    );
    return false;
  });
}
