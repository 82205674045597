import z from 'zod';

import { getRelay } from '../shared/helpers';

import { sanitizeLocale } from '../locales/helpers';

// TODO: Decide if we want to support ALL authorize parameters
const QueryStateExtraSchema = z.object({
  client_redirect: z.string().optional(),
  dc: z.boolean().optional(),
  device_id: z.string().optional(),
  hashed_device_id: z.string().optional(),
  device_name: z.string().optional(),
  el: z.boolean().optional(),
  eu: z.boolean().optional(),
  enforce_country: z.boolean().optional(),
  idp_flow: z
    .enum(['login', 'create_account', 'login_t2', 'login_t2_only'])
    .optional(),
  invite_code: z.string().optional(),
  outvite_code: z.string().optional(),
  puser: z.string().optional(),
  fnuser: z.string().optional(),
  lnuser: z.string().optional(),
  pcountry: z.string().optional(),
  response_type: z.string().optional(),
  ctx_id: z.string().optional(),
  dctx_id: z.string().optional(),
  s_account: z.string().optional(),
  reauth: z.boolean().optional(),
  prompt: z.enum(['none', 'login']).optional(),
  max_age: z.number().int().optional(),
  target_profile: z.string().optional(),
  sign_up_sso: z.boolean().optional(),
  filter: z.string().optional(),
  user_assertion_type: z.string().optional(),
  user_assertion: z.string().optional(),
  //response_mode: z.enum(["query", "fragment"]).optional(),
  enforce_user_assertion: z.boolean().optional(),
  code_challenge: z.string().optional(),
  code_challenge_method: z.enum(['S256', 'plain']).optional(),
  force_complete_account: z.boolean().optional(),
  pba_policy: z.string().optional(),
  hints: z.string().optional(),
  profile_filter: z.string().optional(),
});

/**
 * This module serves as a schema validation between the application and external integrations by
 * parsing the URL Search Parameters, ensuring a clear contract between the two parties.
 *
 * There are currently two modes: "wrapper" and "wrapperless". The "wrapper" mode is
 * used when the application is included using the wrapper main component, while the "wrapperless" mode
 * is specifically for when the application is included directly as an iframe in the context of
 * UME (Unified Mobile Engineering).
 *
 * An additional "popup" mode is available when using the "wrapper" mode.
 *
 * Throught Zod schema validation this module prevents the passingof incorrect or unnecessary parameters
 * and provides TypeScript with a clear understanding of what the developer can access within the parsed queryState variable
 * depending the data passed.
 *
 * - QueryStateExtraSchema handles optional authorize search parameters
 * - BaseQueryStateSchema handles minimal required authorize search parameters needed for the application to function.
 *
 * The exported QueryState type is derived from the QueryStateSchema.
 *
 * Example of data access in different modes using this technique:
 * - In "wrapperless" mode (wrapper=false):
 *   queryState.platform // can be accessed
 * - In "wrapper" mode with 'popup' set to true (wrapper=true, popup=true):
 *   queryState.redirect_uri // can be accessed
 */

const BaseQueryStateSchema = z.object({
  client_id: z.string().min(1),
  locale: z.string().transform(sanitizeLocale),
  pba_policy: z.string().optional(),
  scope: z.string().min(1),
  state: z.string().max(4096).optional(),
  ac: z.string().default('IMS'),
  relay: z.string().default(getRelay()),
  dt: z.boolean().default(false),
  response_type: z.enum(['code', 'token']).default('code'),
});

const ServiceParametersSchema = z.object({
  disable_local_msw: z.boolean().optional(),
  sl_version: z.string().default('1.0.0'),
  sl_build: z.string().default(''),
  sl_buildtime: z.number().default(0),
  st_valid_origin: z.boolean().default(false),
  st_use_federated: z.boolean().default(false),
  st_mock_fedtoken: z.boolean().default(false),
  st_exchange_failed: z.boolean().default(false),
});

const WrapperSchema = z.object({
  wrapper: z.literal(true),
  popup: z.boolean(),
  redirect_uri: z.string(),
  asserted_origin: z.string().min(1),
});

const WrapperLessSchema = z.object({
  wrapper: z.literal(false),
  platform: z.enum(['ios', 'android']),
  popup: z.undefined(),
  config: z.string().min(1),
});

const CombinedWrapperSchema = z.discriminatedUnion('wrapper', [
  WrapperSchema,
  WrapperLessSchema,
]);

const QueryStateSchema = BaseQueryStateSchema.and(CombinedWrapperSchema).and(
  ServiceParametersSchema
);

export type QueryState = z.infer<typeof QueryStateSchema>;

// Used to parse URLSearchParams entries into a Javascript object
export const entriesToObject = (
  searchParams: URLSearchParams
): Record<string, string> => {
  return Object.fromEntries(searchParams);
};

function isValidNumberFormat(s: string): boolean {
  const regex = /^-?(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/;
  return regex.test(s);
}

// Used to parse URLSearchParams entries into a Javascript object
export const parseObjectPrimitives = (obj: Record<string, any>): any => {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      if (typeof v === 'object' && v?.constructor === Object) {
        return [k, parseObjectPrimitives(v)];
      }
      if (isValidNumberFormat(v) && !isNaN(parseFloat(v))) {
        return [k, parseFloat(v)];
      }
      if (v === 'true') return [k, true];
      if (v === 'false') return [k, false];
      if (typeof v === 'string') return [k, v];
      return [k, v];
    })
  );
};

export const parseObjectToString = (obj: Record<string, any>): any => {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [k, v.toString()])
  );
};

// Parses an object against the schema and throws an error if it fails
export const parseSearchParams = (
  searchParams: Record<string, string>
): QueryState => {
  const parsed = parseObjectPrimitives(searchParams);
  const state = QueryStateSchema.safeParse({ ...parsed });

  if (!state.success) {
    throw new z.ZodError(state.error.issues);
  }

  return state.data;
};

/**
 * This export provides a set of utility functions to parse different data representations against the schema.
 *
 * - fromQueryString(qs: string): Converts a query string into a QueryState object.
 * - fromSearchParams(searchParams: URLSearchParams): Transforms URLSearchParams into a QueryState object.
 * - fromObject(obj: Record<string, string>): Parses a plain object against the schema and returns a QueryState object.
 * - toString(obj: QueryState): Converts a QueryState object back into a query string.
 * **/
export const queryState = {
  fromQueryString(qs: string): QueryState {
    const searchParams = new URLSearchParams(qs);
    const obj = entriesToObject(searchParams);
    return parseSearchParams(obj);
  },
  fromSearchParams(searchParams: URLSearchParams): QueryState {
    const obj = entriesToObject(searchParams);
    return parseSearchParams(obj);
  },
  fromObject(obj: Record<string, string>): QueryState {
    return parseSearchParams(obj);
  },
  toObject(obj: QueryState): Record<string, string> {
    return parseObjectToString(obj);
  },
  toString(obj: QueryState) {
    const searchParams = parseObjectToString(obj);
    return new URLSearchParams(searchParams).toString();
  },
};

export const qsModifier = {
  omit(obj: QueryState, omitKeys: string[]): QueryState {
    const entries = Object.entries(obj);
    return Object.fromEntries(
      entries.filter(([key]) => !omitKeys.includes(key))
    ) as QueryState;
  },
};
