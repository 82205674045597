import { ObjectValues } from '$shared/helpers';

export const SOCIAL_PROVIDERS = {
  google: 'google',
  facebook: 'facebook',
  apple: 'apple',
  microsoft: 'microsoft',
  line: 'line',
} as const;

export const isSocialProvider = (
  provider: string
): provider is SocialProvider => {
  return Object.values(SOCIAL_PROVIDERS).includes(provider as SocialProvider);
};

export const DEEPLINK_SOCIAL_PROVIDERS: SocialProvider[] = [
  SOCIAL_PROVIDERS.microsoft,
  SOCIAL_PROVIDERS.line,
];

export const PROVIDERS = {
  adobe: 'adobe',
  passkey: 'passkey',
  ...SOCIAL_PROVIDERS,
} as const;

// Used in the case of API failure.
export const BACKUP_PROVIDERS = [
  PROVIDERS.google,
  PROVIDERS.facebook,
  PROVIDERS.apple,
];

export type Provider = ObjectValues<typeof PROVIDERS>;

export type SocialProvider = ObjectValues<typeof SOCIAL_PROVIDERS>;

export const GOOGLE_SIGN_IN_CLIENT_ID = 'sentry-test-swd-google';
export const GOOGLE_MOCK_PROVIDER = 'google-mock';
export type GoogleMockProvider = typeof GOOGLE_MOCK_PROVIDER;
