import { AppEvents } from '$scripts/events/events';
import { CriticalOutcome } from '$scripts/outcome';
import { criticalErrorFlow, unrecoverableErrorFlow } from '../error/error-flow';

export async function endOfFlow(
  events: AppEvents,
  outcome: CriticalOutcome | never
): Promise<void> {
  if (outcome.isHandled) {
    return;
  }

  try {
    if (outcome.of(CriticalOutcome)) {
      return criticalErrorFlow(events, outcome);
    }
  } catch (e) {
    return unrecoverableErrorFlow(events, outcome);
  }

  throw new Error(
    `Unreachable code reached for value: ${JSON.stringify(outcome)}`
  );
}

export function unhandledEndOfFlow(outcome: never): void {
  throw new Error(
    `Unreachable code reached for value: ${JSON.stringify(outcome)}`
  );
}
