import { UnionToTuple } from '$shared/types/helpers';
import { PROVIDERS } from '$shared/types/social-provider';
import z from 'zod';

export type SocialProvidersEnum = UnionToTuple<keyof typeof PROVIDERS>;
export const Schema = z.object({
  skippable: z.boolean().default(false),
  passkeysEnabled: z.boolean().default(false),
  allowedSocialProviders: z
    .array(z.enum(Object.values(PROVIDERS) as SocialProvidersEnum))
    .optional(),
  background: z.literal('transparent').optional(),
});
